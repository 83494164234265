import {
  API_UPLOAD_PRODUCT_ARTICLE_IMAGE,
} from '@/plugins/api';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Link from '@ckeditor/ckeditor5-link/src/link';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import List from '@ckeditor/ckeditor5-list/src/list';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageTextAlternative from '@ckeditor/ckeditor5-image/src/imagetextalternative';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';

function initCkeditor() {
  const init = {
    editor: ClassicEditor,
    editorConfig: {
      plugins: [
        Essentials,
        Autoformat,
        PasteFromOffice,
        Paragraph,
        Heading,
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Link,
        BlockQuote,
        HorizontalLine,
        FontColor,
        FontBackgroundColor,
        FontSize,
        Code,
        CodeBlock,
        List,
        Alignment,
        Image,
        ImageTextAlternative,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        ImageResize,
        ImageInsert,
        LinkImage,
        Table,
        TableToolbar,
        Indent,
        MediaEmbed,
      ],
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          '|',
          'link',
          'blockQuote',
          'horizontalLine',
          '|',
          'fontcolor',
          'fontbackgroundcolor',
          'fontsize',
          '|',
          'code',
          'codeblock',
          '|',
          'bulletedList',
          'numberedList',
          'alignment',
          '|',
          // 'imageUpload',
          'mediaEmbed',
          'imageinsert',
          'insertTable',
        ],
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: '段落',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading1',
            view: 'h1',
            title: '標題 1',
            class: 'ck-heading_heading1',
          },
          {
            model: 'heading2',
            view: 'h2',
            title: '標題 2',
            class: 'ck-heading_heading2',
          },
          {
            model: 'heading3',
            view: 'h3',
            title: '標題 3',
            class: 'ck-heading_heading3',
          },
          {
            model: 'heading4',
            view: 'h4',
            title: '標題 4',
            class: 'ck-heading_heading4',
          },
          {
            model: 'heading5',
            view: 'h5',
            title: '標題 5',
            class: 'ck-heading_heading5',
          },
          {
            model: 'heading6',
            view: 'h6',
            title: '標題 6',
            class: 'ck-heading_heading6',
          },
        ],
      },
      image: {
        toolbar: [
          'imageStyle:full',
          'imageStyle:alignLeft',
          'imageStyle:alignRight',
          '|',
          'imageTextAlternative',
          '|',
          'linkImage',
        ],
        styles: ['full', 'alignLeft', 'alignRight'],
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
      },
      language: 'en',
    },
  };
  return init;
}

const ckeditor = initCkeditor();

/**
 * 自定义上传图片插件
 */
class MyUploadAdapter {
  constructor(id, loader) {
    this.loader = loader;
    this.id = id;
  }

  async upload() {
    const data = new FormData();
    data.append('prodSerial', this.id);
    data.append('imageFile', await this.loader.file);

    const response = await API_UPLOAD_PRODUCT_ARTICLE_IMAGE(data);
    // 后台返回数据：
    // {"code":0,"msg":"success","data":{"url":"/upload/struts2.jpeg"}}

    // 方法返回数据格式： {default: "url"}
    return {
      // default: process.env.VUE_APP_TARGET_URL + res.data.data.url,
      default: response.data.data,
    };
  }
}

export { ckeditor, MyUploadAdapter };
